export default {
  installerHelpCentre: {
    title: "Tout les articles",
  },
  installerAccountGuide: {
    title: "Votre compte en ligne",
  },
  installerProjectManagementGuide: {
    title: "Vos projets solaires",
  },
  installerPresentationGuide: {
    title: "Études photovoltaïques en ligne",
  },
  installerAdministrationGuide: {
    title: "Gestion administrative automatisée",
  },
  installerQuotingGuide: {
    title: "Mise en relation",
  },
  installerHardwareGuide: {
    title: "Planification du matériel",
  },
  installerPostInstallationGuide: {
    title: "Service après-installation",
  },
  installerPaymentsGuide: {
    title: "Paiements, tarification et remboursements",
  },
  installerLegalGuide: {
    title: "Mentions juridiques",
  },
  householderHelpCentre: {
    title: "Tout les articles",
  },
  householderAccountGuide: {
    title: "Votre portail internet",
  },
  householderPaymentsGuide: {
    title: "Paiements, tarification et remboursements",
  },
  householderPresentationGuide: {
    title: "Choisir son système solaire",
  },
  householderQuotingGuide: {
    title: "Choisir son devis sur-mesure",
  },
  householderTechnicalVisitGuide: {
    title: "Valider la faisabilité",
  },
  householderContractGuide: {
    title: "Investir dans le solaire",
  },
  householderAdministrationGuide: {
    title: "Réalisation des démarches administratives",
  },
  householderInstallationGuide: {
    title: "Pose & mise en service",
  },
  householderLegalGuide: {
    title: "Mentions juridiques",
  },
  householderLearnCentreSolarInstallationPrices: {
    title: "Offres et installations",
  },
  householderLearnCentreSolarSavings: {
    title: "Prix panneaux solaires",
  },
  householderLearnCentreSolarBattery: {
    title: "Solutions de stockage",
  },
  householderLearnCentreProsAndCons: {
    title: "Avantages et inconvénients",
  },
  householderLearnCentreFinancing: {
    title: "Aides et Financement",
  },
  householderLearnCentreHomeSolar: {
    title: "Maintenance",
  },
  subtitle: "Parcourez notre bibliothèque complète de rubriques d'aide",
}
